<template>
  <div class="get-started-page">
    <div class="readable_content">
      <h4>Thanks!</h4>
      <p>
        We'll respond to your request within 2 business days. You should receive an automatic email confirming your
        submission.
      </p>

      <div v-if="!fromBookCall" class="schedule-call">
        <h4>Don't want to wait? You can always schedule a call with our advisors to speak face-to-face.</h4>
        <book-call-update-pr-button :url="bookCallUrl" :email="userPREmail"></book-call-update-pr-button>
      </div>

      <div><img :src="$getAsset('/assets/about/careers/researchers.png')" /></div>
      <h5>Want a head start before we chat?</h5>

      <next-step-family-tree
        :family-tree-route="familyTreeRoute"
        title="Fill in your family tree"
        action-text="Go to My Tree"
      />
      <next-step-ancestors-search :ancestorsSearch="ancestorsSearch" :pinyin="surname" />
      <next-step-guide />
      <next-step-discord></next-step-discord>
    </div>
  </div>
</template>

<script>
import {TAB_ID_AI_SEARCH, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import BookCallUpdatePrButton from '@/base/elements/buttons/BookCallUpdatePrButton';

import {FORM_TYPES} from '@/components/modules/contactUs/constants';
import NextStepAncestorsSearch from '@/components/modules/getStarted/NextStepAncestorsSearch';
import NextStepDiscord from '@/components/modules/getStarted/NextStepDiscord';
import NextStepFamilyTree from '@/components/modules/getStarted/NextStepFamilyTree';
import NextStepGuide from '@/components/modules/getStarted/NextStepGuide';

export default {
  metaInfo: {
    title: 'Thanks for contacting us',
  },
  created() {
    if (this.fromBookCall && this.$route.query.event_start_time && this.$route.query.invitee_email) {
      this.updatePR(this.$route.query.invitee_email, this.$route.query.event_start_time);
      this.$router.push({query: {from: FORM_TYPES.bookcall}});
    }
  },
  computed: {
    ...mapGetters(['userIsLoggedInState', 'clanDetailedState', 'spellingDetailedState']),
    familyTreeRoute() {
      if (this.userIsLoggedInState) {
        return getFamilyTreeRoute(this.$store);
      }
      return {name: 'register', query: {redirect: this.$router.resolve({name: 'familytree-details-my'}).href}};
    },
    ancestorsSearch() {
      const tab =
        !this.$store.getters.featureSwitchesState.SEARCH_AI || this.surname ? TAB_ID_INDEXED_RECORDS : TAB_ID_AI_SEARCH;
      const query = {surname: this.surname, tab: tab};
      return {name: 'search-all-records', query};
    },
    surname() {
      if (this.clanDetailedState) {
        return this.clanDetailedState.name_hant || this.clanDetailedState.name_ch;
      }
      if (this.spellingDetailedState) {
        return this.spellingDetailedState.name;
      }
      return '';
    },
    fromBookCall() {
      return this.$route.query.from === FORM_TYPES.bookcall;
    },
    userPREmail() {
      return sessionStorage.getItem('contactEmail') || this.$store.getters.userEmailState || '';
    },
    bookCallUrl() {
      return this.$route.query.adoption ? process.env.VUE_APP_CALENDLY_ADOPTION_UPDATE_PR_URL : '';
    },
  },
  methods: {
    updatePR(email, startTime) {
      this.$store.dispatch('updateOrCreateContactUsFormAction', {email, callDatetime: startTime});
    },
  },
  name: 'ContactUsSuccessPage',
  components: {
    NextStepDiscord,
    NextStepFamilyTree,
    NextStepAncestorsSearch,
    NextStepGuide,
    BookCallUpdatePrButton,
  },
};
</script>

<style lang="scss" scoped>
.next-step {
  margin-top: 50px;
}

.get-started-page {
  margin-bottom: 100px;
}

.schedule-call {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .book-call-button {
    align-self: center;
    margin-top: 10px;
    min-width: 300px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .book-call-button {
      align-self: stretch;
      min-width: 0;
    }
  }
}
</style>
